import React from "react"

import footerStyle from "./footer.module.css"

const Footer = () => (
  <footer className={footerStyle.footerRow}>
    <p>
      © {new Date().getFullYear()}, Social Vend Ltd. trading as vendOS<br />
      Social Vend Ltd. is a company registered England and Wales (Company No. 10275008)<br />
      Social Vend, 1110 Great West Road, London, TW8 0GP <br /><br />
      Social Vend®, Vendmax® and Vendmini® are registered trademarks of Social Vend Ltd. in the United Kingdom and/or other countries.<br /> All other trademarks cited herein are the property of their respective owners.
    </p>
  </footer>
)

export default Footer
