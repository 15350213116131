import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./_ui/Logo";

import headerStyle from "./header.module.css"

const Header = ({ siteTitle }) => (
  <header className={headerStyle.header}>
      <div className="logo">
        <Link to="/" >
          <Logo />
        </Link>
      </div>
      <div className={headerStyle.nav}>
        <ul>
          <li><a href="https://developer.vendos.io">Documentation</a></li>
        </ul>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
