/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "./grid.css"

const Layout = ({ children }) => {
  // let data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     prismic {
  //       allMetadatas {
  //         edges {
  //           node {
  //             site_title
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // data = data.prismic.allMetadatas.edges.slice(0, 1).pop();

  const site = {
    title: `vendOS | The worlds first platform for smart vending machines`,
    description: `Welcome to the worlds first smart vending platform, made up of two core components; Mothership & FieldCommand.`,
    author: `@vend_os`,
  }

  return (
    <>
      <Header siteTitle={site.title} />
      <div>
        <main className="wrapper">{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
