import React from "react";

const Logo = () => (
    <svg width="144" height="27" viewBox="0 0 144.1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#00FFCE" d="M41.3,7.3l-6.4,16.9h-4.3L24.2,7.3h4.2l4.4,12.3l4.4-12.3H41.3z"/>
        <path fill="#00FFCE" d="M46.2,17.1c0.2,2.4,2.3,4.2,4.7,4.2c1.8,0.1,3.4-0.9,4.1-2.6l3.4,1c-1,2.7-3.4,4.9-7.5,4.9
            c-4.8,0-8.6-3.7-8.6-8.9C42.1,11,45.8,7,50.5,6.8c0.1,0,0.2,0,0.3,0c4.9,0,8,3.6,8,8.7v1.6L46.2,17.1z M50.9,10.2
            c-2.3-0.1-4.3,1.6-4.6,3.8h9C55,11.8,53.1,10.1,50.9,10.2z"/>
        <path fill="#00FFCE" d="M62.1,7.3h3.3l0.6,2c1.2-1.6,3.1-2.6,5.1-2.5c3.8,0,6,2.2,6,7.1v10.2h-4v-9.7c0-3-1.4-3.9-3.3-3.9
            c-2.1,0-3.7,1.5-3.7,4.3v9.3h-4.1V7.3z"/>
        <path fill="#00FFCE" d="M80.6,15.7c0-4.9,3.3-8.9,8.2-8.9c2-0.1,4,0.8,5.2,2.4V0.5H98v23.7h-3.4l-0.5-2.1c-1.3,1.7-3.3,2.6-5.4,2.5
            C84,24.6,80.6,20.7,80.6,15.7z M94,15.7c0-3.1-2-5.2-4.7-5.2s-4.7,2.2-4.7,5.2s2,5.3,4.7,5.3S94,18.8,94,15.7z"/>
        <path fill="#00FFCE" d="M101.4,12.3C101.4,5,106.7,0,113.3,0s11.9,5,11.9,12.3s-5.3,12.3-11.9,12.3S101.4,19.8,101.4,12.3z M123.3,12.3
            c0-6.1-4.4-10.6-10-10.6s-10,4.5-10,10.6c0,6.3,4.3,10.6,10,10.6S123.3,18.6,123.3,12.3z"/>
        <path fill="#00FFCE" d="M127.1,17.8l1.8-0.5c0.9,3.8,3.2,5.6,7,5.6c3.4,0,6.3-1.6,6.3-4.9c0-1.6-0.1-3.8-6.1-4.9c-4.5-0.8-8-2.4-8-6.6
            s3.7-6.5,7.8-6.5c4.5,0,6.8,2.5,7.9,6.5L142,7c-0.7-3.1-2.4-5.3-6.1-5.3c-3.5,0-6,1.8-6,4.8s2.3,3.9,6.5,4.7c5,1,7.7,2.8,7.7,6.8
            s-3.6,6.6-8.2,6.6S128.3,22.5,127.1,17.8z"/>
        <path fill="#00FFCE" d="M12.3,4.8c0.9-0.2,3.1-0.7,3.1-2.5l0,0c0-0.2,0-0.3-0.1-0.5l-0.1-0.3l0,0H15c0,0-5.6,0.7-7.4,1l0,0
            C6,2.8,4.3,3,3.1,3.3C2.2,3.5,0,4,0,5.8l0,0C0,7.5,2.2,8,3.1,8.2c1.2,0.3,2.8,0.5,4.4,0.7L9,9.2L7.5,9.4c-1.6,0.2-3.2,0.4-4.4,0.7
            C2.2,10.3,0,10.8,0,12.6h0c0,1.7,2.2,2.2,3.1,2.4c1.2,0.3,2.8,0.5,4.4,0.7L9,16l-1.6,0.2c-1.6,0.2-3.2,0.4-4.4,0.7l0,0
            C2.2,17.1,0,17.7,0,19.4h0c0,1.7,2.2,2.3,3.1,2.5c1.2,0.3,2.8,0.5,4.4,0.7c0.5,0.1,1,0.1,1.6,0.2L7.5,23c-1.6,0.2-3.2,0.4-4.4,0.7
            C2.2,24,0,24.5,0,26.2l0,0c0,0.2,0,0.3,0.1,0.5L0.2,27l0,0h0.3l7.4-1l0,0c1.6-0.2,3.2-0.4,4.5-0.7c0.9-0.2,3.1-0.7,3.1-2.5l0,0
            c0-1.7-2.2-2.3-3.1-2.5c-1.2-0.3-2.8-0.5-4.4-0.7l-1.6-0.2l1.6-0.2c1.6-0.2,3.2-0.4,4.4-0.7c0.9-0.2,3.1-0.7,3.1-2.5l0,0
            c0-1.7-2.2-2.2-3.1-2.5C11,13.2,9.5,13,7.9,12.8l-1.6-0.2l1.6-0.2c1.6-0.2,3.2-0.4,4.4-0.7l0,0c0.9-0.2,3.1-0.7,3.1-2.5l0,0
            c0-1.7-2.2-2.3-3.1-2.5C11,6.4,9.5,6.2,7.9,6L6.3,5.8l1.6-0.2C9.5,5.3,11,5.1,12.3,4.8z"/>
    </svg>
);

export default Logo;
